import React from "react";

import { LayoutMain } from "../layouts/layout-main/layout-main";
import { PostTeaser } from "../components";
import { useArticles } from "../hooks/use-articles";

const Articles = () => {
  const articles = useArticles();

  return (
    <LayoutMain isBlogPost={false} title="Articles">
      <article className="posts">
        <h1 className="page-title">Articles</h1>
        {articles.map((article) => (
          <PostTeaser key={article.id} article={article} />
        ))}
      </article>
    </LayoutMain>
  );
};

export default Articles;
